import React, { Fragment } from "react";
import { ThemeProvider } from "styled-components";
import Layout from "../containers/Home/Layout";
import { interiorTheme } from "common/theme/interior";
import "common/assets/css/main-page.css";

import Seo from "components/seo";

import TitleBar2 from "common/components/Titlebar2";
import SingleServicePage from "../containers/Home/pages.style";
import ServiceSectionTwo from "../containers/Sections/ServiceSectionTwo";
import ServiceSectionThree from "../containers/Sections/ServiceSectionThree";
import ServiceSectionOne from "../containers/Sections/ServiceSectionOne";
import ServiceSectionFour from "../containers/Sections/ServiceSectionFour";
import ServiceSectionFive from "../containers/Sections/ServiceSectionFive";

const TapinClipinForMen = () => {

  const seoData = {
    title: 'Tape in and Clip in Hair Extension - Radiance Hair Studio',
    description: 'Discover the elegance of tape in and clip in hair extensions at Radiance Hair Studio. Elevate your look with our premium extensions. Transform your hair today.',
    keywords: ['Tape in and Clip in Hair Extension'],
  };

  const schemaData = {
    "@context": "https://schema.org",
    "@type": "Service",
    "@id": "Service",
    "alternateName": "Radiance Hair Studio",
    "areaServed": "New Delhi",
    "image": [
      "https://www.radiancehairstudio.com/overlay7.png",
      "https://www.radiancehairstudio.com/overlay7.png",
      "https://www.radiancehairstudio.com/overlay7.png",
      "https://www.radiancehairstudio.com/overlay7.png",
      "https://www.radiancehairstudio.com/service-images/Tape-In-Clip-In-on-Hair-Extension-for-Men.jpg",
      "https://www.radiancehairstudio.com/service-images/Tape-In-Clip-In-on-Hair-Extension-for-Men.jpg",
      "https://www.radiancehairstudio.com/tape-in-and-clip-in-hair-extension1.png",
      "https://www.radiancehairstudio.com/tape-in-and-clip-in-hair-extension2.png",
      "https://www.radiancehairstudio.com/help-image-1.png"
    ],
    "logo": "https://www.radiancehairstudio.com/static/radiance-logo-721b0cd4def3d400c04ba899afd38810.png",
    "additionalType": "https://www.radiancehairstudio.com/tape-in-and-clip-in-hair-extension.php",
    "category": "https://www.radiancehairstudio.com/tape-in-and-clip-in-hair-extension.php",
    "description": "Discover the elegance of tape in and clip in hair extensions at Radiance Hair Studio. Elevate your look with our premium extensions. Transform your hair today.",
    "disambiguatingDescription": "Tape in and Clip in Hair Extension",
    "serviceType": "Tape In & Clip In on Hair Extension"
  };  

  const sectionS1 = [
    {
      title: "Customized Wigs for Women",
      img: "/custom-wigs.png",
    },
    {
      title: "Wig for Women’s",
      img: "/womens-wig.png",
    },
    {
      title: "Hair Replacement",
      img: "/hair-replacement.png",
    },
    {
      title: "Non-Surgical Hair Replacement",
      img: "/non.png",
    }
  ];


  const sectionS2 = [
    {
      img: "/service-images/Tape-In-Clip-In-on-Hair-Extension-for-Men.jpg",
      content: (
        <div>
          <h2>Tape In & Clip In Hair Extensions</h2>
          <p>
           <a href="/gallery.php" style={{ color: "#373737"}}>Hair extensions</a> are applied on your head usually in three styles –
            applying glues, using tape-ins and by using clip-ins. Although the
            glues applied are medicated, still, some feel a panic to apply the
            chemicals. In such cases, tape-ins and clip-ins are the best options.
            In the case of tape-ins, both side tapes are applied o your scalp and
            extensions are attached to it.
          </p>
          <h2>Radiance Provide Advance Tape In & Clip In solutions!</h2>
          <p>
            We at Radiance Hair Studio provide you with the best type of tape-in
            hair extension that will last for 4 weeks. We use natural human hair
            instead of synthetic hair so that you can maintain your natural hair,
            which remains trimmed, and the extensions easily with shampoos and
            other regular items. The appearance that you will get in the process
            is fabulous.
          </p>
          <p>
            Tape-ins at times does put some obligations to the clients. Some tapes
            are such that they must not be wet or if made wet, they must not be
            combed. Hence, clients often choose clip-ins as the substitute.
            Clip-in extensions can be used as a cap as they can be opened and
            re-worn anytime and for that, you need not to visit any salon.
          </p>
          <p>
            Radiance will give you a shiny and beautiful look with clip-ins that
            will make you feel bold once again. We apply the clips in such a way
            that you can easily attach and remove. We ensure that the fact – you
            applied extended hair remains known to you alone. So, none except you
            will be able to discover the secret of your long hair, except you.
          </p>
        </div>
      ),
    },
  ];
  
  const sectionS3 = [
    {
      smallImg: "/tape-in-and-clip-in-hair-extension1.png",
      largeImg: "/tape-in-and-clip-in-hair-extension2.png",
      content: (
        <div>
          <h2>Benefits of Applying Tape-in Extensions</h2>
          <p></p>
          <ul    >
            <li>
              Tape-in is to be used over the head-scalp. So this is an extension,
              where there is no need for any surgery. The full process is easy to
              be applied and is absolutely pain-free.
            </li>
            <li>
              You can change your styles easily while applying tape-ins. However,
              you need to visit the Salon for the purpose.
            </li>
            <li>
              The life span of tape-in extensions is nearly four to six weeks.
              Hence, if you are not going to change your styles fast, then they
              will serve you for more than a months.
            </li>
            <li>
             <a href="/hair-weaving-for-mens.php" style={{ color: "#373737"}}>Hair</a> lengths of the extensions are perfect for a women's. Those
              usually come up in different styles and formats. The best among
              those will be guided to you by the professionals.
            </li>
          </ul>
          <h2>Benefits of Applying Clip-in Extensions</h2>
          <ul    >
            <li>
              Clip-ins is going to give
              you a lot of flexibility as you can attach or detach them at your
              wish anytime at your home.
            </li>
            <li>
              You can maintain those for a
              long time by using shampoos or serums if you are provided an
              extension cap, which is made of human hair.
            </li>
            <li>
              Longevity of the caps is
              long enough and you can wear those, either with your trimmed hair or
              by applying clips at your bald part.
            </li>
            <li>
              If you are experiencing
              semi-permanent hair loss and want to apply for the clip-in
              extensions at those affected areas, you will have such option too in
              this case.
            </li>
          </ul>
        </div>
      ),
    },
  ];
  
  const sectionS4 = [
    {
      title: "FAQs about the Tap-in Extension.",
      description: "",
      description2: "",
      faq: [
        {
          title: "Will this damage my natural hair?",
          description: "In the case of tape-in, you must know that they are externally applied extensions. So, they have no role to play with your natural hair or scalp.",
        },
        {
          title: "Can I swim or sleep with these extensions?",
          description: "Tape-ins does have a lifespan, but that is not going to decrease with swimming or sleeping. The adhesive part remains at the scalp and they are not damaged before their life-span.",
        },
        {
          title: "What is the lifetime of Tape In extensions?",
          description: "The tape-ins that we provide from Radiance has usually 4-6 weeks’ life span.",
        },
        {
          title: "Why will I apply Tape In extension?",
          description: "You want your hair to look long, shiny and yet natural. This is the key reason to apply for these extensions. There are other methods too for the extension. However, those are painful and time-taking procedures too. The best way to get an instant and flexible looking long hair, tape-in is the best methods. So, apply those and feel the difference.",
        }
      ],
    },
  ];
  const sectionS5 = [
    {
      title: "FAQs about the Clip-in Extension.",
      description: "",
      description2: "",
      faq: [
        {
          title: "Is the process painful?",
          description: "The literal answer to this question is – by No means. The extension application method of clip-in that we apply at Radiance is fully an external one and there is surgery into it. So, there is not even minute chance of pain in the entire process.",
        },
        {
          title: "Will I have to wear the extension all the time?",
          description: "Clip-in extensions are very much easy to be opened and re-worn. The important declaration here is that – you will not have to visit us at Radiance for the purpose of re-wearing your extension. So, open it up, when you feel and re-wear, as you need it.",
        },
        {
          title: "What is the life-span on Clip In extensions?",
          description: "What is the life-span on Clip In extensions?",
        },
        {
          title: "Can I use shampoos?",
          description: "Yes, you can. The extensions are made of natural human hair and hence can be washed easily with shampoos.",
        },
      ],
    },
  ];

  return (
    <ThemeProvider theme={interiorTheme}>
      <Fragment>
      <Seo {...seoData} schemaData={schemaData} />
        <Layout>
          <TitleBar2 title="Tape In & Clip In on Hair Extension" banner="/tape-in-clipping-in-extension.png" />
          <SingleServicePage>
            <ServiceSectionOne data={sectionS1} title="Tape In & Clip In on Hair Extension" />
            <ServiceSectionTwo data={sectionS2} />
            <ServiceSectionThree data={sectionS3} />
            <ServiceSectionFour data={sectionS4} />
            <ServiceSectionFive data={sectionS5} />
          </SingleServicePage>
        </Layout>
      </Fragment>
    </ThemeProvider>
  );
};
export default TapinClipinForMen;
